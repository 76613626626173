<template>
  <b-modal
    :id="`creative-detail-modal-${creative.id}`"
    :modal-class="modalClasses"
    :hide-footer="true"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1 wrap-text">
        <span>{{ localCreative.name }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <!-- DETAILS -->
      <template v-if="!isEdit">
        <h3 class="font-size-medium mb-3">{{ $t('general_data') }}</h3>
        <p class="mb-4 wrap-text">
          <strong>{{ $t('name') }}:</strong> {{ localCreative.name }}<br/>
          <strong>{{ $t('destination_url') }}:</strong> {{  Array.isArray(localCreative.url) ? localCreative.url[0] : localCreative.url }}<br/>
          <strong>{{ $t('format') }}:</strong> {{ localCreative.format | formatCreativeFormat }}<br/>
          <strong v-if="localCreative.format !== 'YOUTUBE'">{{ $t('size') }}:</strong> {{ localCreative.layout }}<br/>
          <template v-if="['NATIVE_DISPLAY', 'PUSH', 'YOUTUBE'].includes(localCreative.format)">
            <template v-if="localCreative.format !== 'YOUTUBE'">
              <strong>{{ $t('advertisers_display_name') }}:</strong> {{ localCreative.advertiser_display_name }}<br/>
              <strong>{{ $t('caption_url') }}:</strong> {{ localCreative.caption_url }}<br/>
            </template>
            <strong>{{ $t('title') }}:</strong> {{ localCreative.headline }}<br/>
            <strong>{{ $t('body_text') }}:</strong> {{ localCreative.body_text }}<br/>
            <strong>{{ $t('call_to_action') }}:</strong> {{ localCreative.call_to_action }}<br/>
          </template>
          <template v-if="localCreative.format === 'SEARCH'">
            <strong>Títulos:</strong>
            <ul>
              <li v-for="(headline, index) in localCreative.headline" :key="index">
                {{ headline }}
              </li>
            </ul>
            <strong>Corpo do texto:</strong>
            <ul>
              <li v-for="(text, index) in localCreative.body_text" :key="index">
                {{ text }}
              </li>
            </ul>
          </template>
          <!--<strong>Disponibilidade:</strong> {{ availability }}<br/> -->
          <strong>{{ $t('status') }}:</strong> {{ localCreative.status | formatStatus }}
        </p>
        <template v-if="localCreative.format !== 'SEARCH'">
          <h3 class="font-size-medium">{{ $t('creative') }}</h3>
          <div class="text-center">
            <a :href="creative.file" target="_blank">
              <img v-if="contentType === 'IMAGE'" :src="creative.file" :alt="creative.name" />
              <video v-if="contentType === 'VIDEO'" controls width="300" height="250" autoplay muted>
                  <source :src="creative.file" :alt="creative.name" type="video/mp4">
              </video>
            </a>
            <div class="mt-3 text-center" v-if="creative.format === 'YOUTUBE'">
              <iframe width="460" height="215" :src="`https://www.youtube.com/embed/${creative.youtube_id}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </template>
        <template v-if="localCreative.logo">
          <h3 class="font-size-medium mt-3">{{ $t('logo') }}</h3>
          <div class="text-center">
            <a :href="creative.logo" target="_blank">
              <img :src="creative.logo" :alt="creative.name" width="100" />
            </a>
          </div>
        </template>
        <div class="text-center mt-4">
          <Button
            class="btn-primary"
            @click="isEdit = true"
            v-if="!creative.is_read_only"
          >
            {{ $t('edit') }}
          </Button>
        </div>
      </template> <!-- END DETAILS -->
      <!-- EDIT -->
      <template v-else>
        <UpsertCreativeForm
          :advertiserId="localCreative.advertiser.id"
          :creative="localCreative"
          :format="localCreative.format"
          @creative-updated="handleUpdated"
          @upsert-creative-error="handleError"
        />
      </template> <!-- END EDIT -->
    </div>
  </b-modal>
</template>

<script>

import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import UpsertCreativeForm from '@/components/creatives/UpsertCreativeForm'

export default {
  name: 'CreativeDetailModal',
  props: ['creative'],
  components: {
    AlertWraper,
    Button,
    UpsertCreativeForm
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      isEdit: false,
      loader: false,
      localCreative: null
    }
  },
  created () {
    this.localCreative = this.creative
  },
  computed: {
    availability () {
      const availability = []
      if (this.localCreative.is_desktop) availability.push('Desktop')
      if (this.localCreative.is_mobile) availability.push('Mobile')
      if (this.localCreative.is_app) availability.push('Aplicativos')

      return availability.length > 0 ? availability.join(', ') : this.$t('no_availability')
    },
    /**
     * Get content type (image or video) of creative
     */
    contentType () {
      if (!this.localCreative) return
      if (this.localCreative.format === 'NATIVE_DISPLAY' || this.localCreative.format === 'DISPLAY' || this.localCreative.format === 'PUSH') return 'IMAGE'
      else if (this.localCreative.format === 'DISPLAY_VIDEO') return 'VIDEO'
      return ''
    },
    modalClasses () {
      const classes = ['default-modal']
      if (
        this.isEdit &&
        (this.localCreative.format === 'NATIVE_DISPLAY' || this.localCreative.format === 'PUSH')
      ) classes.push('modal-size-lg')
      return classes.join(' ')
    }
  },
  methods: {
    /**
     * Handle updated
     */
    handleUpdated (creative) {
      this.isEdit = false
      this.localCreative = creative
      this.content = null
    },
    /**
     * Handle error
     */
    handleError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * On hidden
     */
    hidden () {
      this.isEdit = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  width: 100%;
  word-wrap: break-word;
}
</style>
